import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

const Login = ({ location }) => {
  return (
    <Layout>
      <h2>MI Login Page</h2>
      <p>
        <a href="https://www.contentful.com/" target="_blank" rel="noopener noreferrer">
          Edit custom content (Contentful)
        </a>
      </p>
      <p>
        <a href="https://www.missionone.com.au/" target="_blank" rel="noopener noreferrer">
          Edit Events (MissionOne)
        </a>
      </p>
    </Layout>
  )
}

export default Login

Login.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
